(function() {
    window.apos = window.apos || {};
    var data = document.body && document.body.getAttribute('data-apos');
    Object.assign(window.apos, JSON.parse(data || '{}'));
    if (data) {
      document.body.removeAttribute('data-apos');
    }
    if (window.apos.modules) {
      for (const module of Object.values(window.apos.modules)) {
        if (module.alias) {
          window.apos[module.alias] = module;
        }
      }
    }
})();
import 'vite/modulepreload-polyfill';
import "./@apostrophecms/image-widget/src/index.scss";
import "./@apostrophecms/form/src/index.scss";
import "./@apostrophecms/form-widget/src/index.scss";
import "./asset/src/index.scss";
import "./article-widget/src/carousel.scss";
import "./palette-page/src/index.scss";
import index_0App from "./@apostrophecms/util/src/index.js";
import index_1App from "./@apostrophecms/i18n/src/index.js";
import index_2App from "./@apostrophecms/admin-bar/src/index.js";
import main_3App from "./@apostrophecms/home-page/src/main.js";
import index_4App from "./@apostrophecms/video-widget/src/index.js";
import index_5App from "./@apostrophecms/form/src/index.js";
import index_6App from "./@apostrophecms/form-widget/src/index.js";
import index_7App from "./@apostrophecms/form-text-field-widget/src/index.js";
import index_8App from "./@apostrophecms/form-textarea-field-widget/src/index.js";
import index_9App from "./@apostrophecms/form-select-field-widget/src/index.js";
import index_10App from "./@apostrophecms/form-radio-field-widget/src/index.js";
import index_11App from "./@apostrophecms/form-checkboxes-field-widget/src/index.js";
import index_12App from "./@apostrophecms/form-file-field-widget/src/index.js";
import index_13App from "./@apostrophecms/form-boolean-field-widget/src/index.js";
import index_14App from "./@apostrophecms/form-conditional-widget/src/index.js";
import index_15App from "./form-range-field-widget/src/index.js";
import index_16App from "./asset/src/index.js";
import index_17App from "./two-column-widget/src/index.js";
import index_18App from "./topic-page/src/index.js";
import topic_19App from "./article-widget/src/topic.js";
import carousel_20App from "./article-widget/src/carousel.js";
index_0App();
index_1App();
index_2App();
main_3App();
index_4App();
index_5App();
index_6App();
index_7App();
index_8App();
index_9App();
index_10App();
index_11App();
index_12App();
index_13App();
index_14App();
index_15App();
index_16App();
index_17App();
index_18App();
topic_19App();
carousel_20App();
